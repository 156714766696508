::-webkit-scrollbar {
  display: none;
  width: 10px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #a3a3a3;
  border-radius: 10px;
}

.chatlist:hover ::-webkit-scrollbar {
  display: block;
  transition: all 5s ease-out;
}
